/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

import classnames from 'classnames';
import {
  Button,
  ColoredSection,
  DisplayText,
  Header,
  HeroImage,
  LayoutUI,
  LeadText,
  Stack,
  Subheader,
  Tabs,
  TextStyle
} from '../../../../ui-kit';
import Layout from '../../../../components/layout';
import Navbar from '../../../../components/navbar/navbar';
import MobileNavbar from '../../../../components/mobile-navbar/mobile-navbar';

import HandsImage from './images/kids-hands.jpg';
import bullet from './images/bullet.svg';

import * as styles from './PreferredSchoolProgram.module.scss';

import headerImage from '../../../../assets/images/education-program/preferred-school-program/preferred-schools-header.jpg';
import trainingIcon from './images/training-icon.svg';
import communityIcon from './images/community-icon.svg';
import rewardsIcon from './images/rewards-icon.svg';
import pricingIcon from './images/pricing-icon.svg';
import productsIcon from './images/products-icon.svg';

import bronzeStaffIcon from './images/1-person.svg';
import bronzeBooksIcon from './images/1-book.svg';

import silverStaffIcon from './images/2-person.svg';
import silverBooksIcon from './images/2-books.svg';

import platinumStaffIcon from './images/5-person.svg';
import platinumBooksIcon from './images/3-books.svg';

const CONTENT = {
  headerImage,
  FEATURE_TABS: [
    {
      icon: trainingIcon,
      label: 'Training & Support',
      title: 'Training & Continued Resources',
      text:
        'Preferred Schools will have at least one trained teacher at their school, trained by Project Happiness staff on how to best implement the curriculum, and immediate applications to their own classroom. After training, all trained teachers will receive access to a private Facebook group to further support one another with questions, motivation, and the newest ideas.'
    },
    {
      icon: pricingIcon,
      label: 'Best Prices',
      title: 'Bulk Pricing – All Year',
      text:
        'Our Preferred Schools receive continuous bulk pricing, at the lowest price, on any purchases throughout the year they are recognized – whether they purchase 2 more books for new students, or 100 as a gift to graduating students.'
    },
    {
      icon: rewardsIcon,
      label: 'Recognition',
      title: 'Proudly Display Your Distinction',
      text:
        'Preferred Schools have chosen to prioritize emotional resilience and well being, and we think they should be proud of it! Each school receives a plaque stating their designated distinction as a Project Happiness School.'
    },
    {
      icon: productsIcon,
      label: 'Products',
      title: 'Our Bestselling Products',
      text:
        'We included our best materials: the award-winning documentary, Project Happiness Handbook, along with our curriculum & training videos, SEL assessment tools, parent/student homework, student workbook, and a guide on why we measure SEL.'
    },
    {
      icon: communityIcon,
      label: 'Community',
      title: 'Connect With Others Globally',
      text:
        'For schools who wish to be paired up, we provide contact information for a buddy school, so they can be pen pals. Our goal is to help the world feel a little more connected, and what better way to connect than through two classrooms focused on happiness?'
    }
  ],
  PROGRAM_OPTIONS: [
    {
      color: '#AE5E33',
      packageName: 'Bronze',
      staffCount: 1,
      booksCount: 50,
      cost: '$1,095',
      value: '$1,450',
      url:
        'https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45234107982',
      staffIcon: bronzeStaffIcon,
      booksIcon: bronzeBooksIcon
    },
    {
      color: '#A6A6A6',
      packageName: 'Silver',
      staffCount: 3,
      booksCount: 100,
      cost: '$1,995',
      value: '$3,275',
      url:
        'https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45333631886',
      staffIcon: silverStaffIcon,
      booksIcon: silverBooksIcon
    },
    {
      color: '#535353',
      packageName: 'Platinum',
      staffCount: 5,
      booksCount: 200,
      cost: '$3,695',
      value: '$5,725',
      url:
        'https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45333631950',
      staffIcon: platinumStaffIcon,
      booksIcon: platinumBooksIcon
    }
  ]
};

export default class PreferredSchoolProgram extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0
    };

    this.handleTabClick = this.handleTabClick.bind(this);
  }

  handleTabClick(index) {
    this.setState({ selectedTab: index });
  }

  render() {
    return (
      <Layout>
        <Navbar />
        <MobileNavbar />
        <div>
          <HeroImage height="large" image={CONTENT.headerImage} noMargin>
            <LayoutUI narrow>
              <Stack alignment="center" vertical spacing="loose">
                <div className={styles.Title}>
                  <DisplayText size="small">
                    Preferred School Program
                  </DisplayText>
                </div>
                <Button
                  color="yellow-dark"
                  href="https://shop.projecthappiness.org/products/middle-school-starter-pack?variant=45234107982"
                >
                  Order Now
                </Button>
              </Stack>
            </LayoutUI>
          </HeroImage>

          <div className={styles.PackageDetailSection}>
            <LayoutUI narrow>
              <Stack alignment="center" vertical spacing="loose">
                <TextStyle center>
                  <Header size="small">
                    The Ideal Package for a <br />
                    School-Wide Happiness Experience
                  </Header>
                </TextStyle>
                <TextStyle color="gray-darker">
                  <LeadText alignment="center">
                    Congratulations, you’re on your way to bringing happiness to
                    your school! Our Preferred School Program incorporates our
                    best materials, with the most access to training, and
                    additional exclusive perks. Read more about each perk.
                  </LeadText>
                </TextStyle>
              </Stack>
            </LayoutUI>

            <LayoutUI narrow>
              <div className={styles.TabStack}>
                {CONTENT.FEATURE_TABS.map(({ icon, label }, index) => (
                  <div
                    key={label}
                    onClick={() => this.handleTabClick(index)}
                    className={classnames(
                      styles.TabStackItem,
                      index === this.state.selectedTab ? styles.TabActive : null
                    )}
                  >
                    <div className={styles.FeatureTab}>
                      <img src={icon} alt={label} />
                    </div>
                    <p
                      style={{
                        marginTop: '15px',
                        width: '100px',
                        textAlign: 'center'
                      }}
                    >
                      {label}
                    </p>
                  </div>
                ))}
              </div>
            </LayoutUI>

            <div className={styles.FeatureTabContainer}>
              <LayoutUI narrow>
                <Tabs height={425} selectedIndex={this.state.selectedTab}>
                  {CONTENT.FEATURE_TABS.map(({ title, text }) => (
                    <Tabs.Pane key={title}>
                      <Stack
                        noWrap
                        vertical
                        spacing="loose"
                        distribution="center"
                      >
                        <TextStyle center>
                          <Header size="small">{title}</Header>
                        </TextStyle>
                        <TextStyle color="gray-darker">
                          <LeadText>{text}</LeadText>
                        </TextStyle>
                      </Stack>
                    </Tabs.Pane>
                  ))}
                </Tabs>
              </LayoutUI>
            </div>
          </div>

          <HeroImage image={HandsImage} noMargin />

          <div className={styles.TestimonyContainer}>
            <LayoutUI narrow>
              <Stack
                alignment="center"
                distribution="center"
                spacing="loose"
                vertical
              >
                <Subheader>What people are saying</Subheader>
                <TextStyle center color="gray-dark">
                  <div
                    style={{
                      fontFamily: 'Circular',
                      fontSize: '26px',
                      fontStyle: 'normal',
                      color: '#9B9B9B',
                      letterSpacing: '0',
                      lineHeight: '35px'
                    }}
                  >
                    “Project Happiness changed the culture in our school...the
                    ripple effect from Project Happiness' programs cannot be
                    overstated. Thank you Project Happiness for changing our
                    understanding of life and each other.”
                    <i>
                      – Carol Valentino-Barry, Ridgewood High School
                      Communications Specialist, Norridge, IL
                    </i>
                  </div>
                </TextStyle>
              </Stack>
            </LayoutUI>
          </div>

          <div className={styles.ProgramPackageOptions}>
            <div className={styles.HeaderWrapper}>
              <Header>Program Package Options</Header>
            </div>

            <div className={styles.ProgramOptionList}>
              {CONTENT.PROGRAM_OPTIONS.map(option => (
                <div key={option.packageName} className={styles.ProgramOption}>
                  <div
                    className={styles.ColorBar}
                    style={{ background: option.color }}
                  />

                  <div className={styles.InnerWrapper}>
                    <div className={styles.Heading}>{option.packageName}</div>

                    <div className={styles.HeadingItem}>
                      <img src={option.staffIcon} alt="1-trained-staff" />
                      <span>
                        <strong>{option.staffCount}</strong> Trained Staff
                      </span>
                    </div>

                    <div className={styles.HeadingItem}>
                      <img src={option.booksIcon} alt="50-books" />
                      <span>
                        <strong>{option.booksCount}</strong> Books a year
                      </span>
                    </div>

                    <hr />

                    <div className={styles.ListItem}>
                      <img src={bullet} alt="" />
                      <span>Curriculum & Materials</span>
                    </div>

                    <div className={styles.ListItem}>
                      <img src={bullet} alt="" />
                      <span>Annual Film Screening</span>
                    </div>

                    <div className={styles.ListItem}>
                      <img src={bullet} alt="" />
                      <span>Buddy Program</span>
                    </div>

                    <div className={styles.ListItem}>
                      <img src={bullet} alt="" />
                      <span>Facebook Group</span>
                    </div>

                    <div className={styles.ListItem}>
                      <img src={bullet} alt="" />
                      <span>Pay It Forward Activity</span>
                    </div>

                    <div className={styles.ListItem}>
                      <img src={bullet} alt="" />
                      <span>Plaque</span>
                    </div>

                    <div className={styles.ButtonWrapper}>
                      <Button color="yellow-dark" href={option.url}>
                        Order Now
                      </Button>
                    </div>

                    <div className={styles.ValueText}>{option.cost}</div>

                    <div className={styles.ValueSubtext}>
                      Valued at: {option.value}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <ColoredSection color="blue">
            <LayoutUI narrow>
              <Stack alignment="center" noWrap>
                <Stack.Item fill>
                  <Header>
                    Not sure you want to commit yet? Begin with the Starter Pack
                    2
                  </Header>
                </Stack.Item>
                <Button
                  color="blue-dark"
                  href="https://shop.projecthappiness.org/collections/all-products/products/k-5-starter-pack"
                >
                  Order now
                </Button>
              </Stack>
            </LayoutUI>
          </ColoredSection>
        </div>
      </Layout>
    );
  }
}
